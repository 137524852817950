<template>
  <div>
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug"/>
    <div class="content overflow-hidden">
      <validation-observer ref="VFormSubmit">
        <b-form @submit.prevent="doSubmit">
          <div class="card">
            <div class="card-header bg-white">
              <h6 class="card-title font-weight-semibold">Verifikasi Dokter Verifikator</h6>
            </div>
            <div class="card-body">
              <div class="alert alert-info">Verifikasi pilihan ICD-10 dan ICD-9-CM</div>
              <div class="form-row">
                <div class="col-md-6">
                  <table class="table table-bordered table-sm">
                    <tr>
                      <td width="50%">
                        <div class="result_tab">
                          <h4>Tgl. Masuk</h4>
                          <p>{{rowReg.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}} WIB</p>
                        </div>
                      </td>
                      <td width="50%">
                        <div class="result_tab">
                          <h4>Tgl. Keluar</h4>
                          <p>{{rowReg.aranr_keluar_ranap_at | moment("DD MMMM YYYY, HH:mm")}} WIB</p>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <div class="result_tab">
                          <h4>Alasan Masuk RS</h4>
                          <pre class="pre-input mb-1">{{rowVerifikasi.aranrv_alasan_masuk||"-"}}</pre>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-header bg_head_panel">
                      <h6 class="card-title font-weight-semibold">Alergi</h6>
                    </div>
                    <div class="card-body p-3">
                      <div class="form-row" v-if="rowResumeMedis.aranresm_has_alergi == 'Y'">
                        <div class="col-md-12">
                            <div class="table-responsive">
                            <table class="table table-bordered">
                                <tbody>
                                    <tr v-for="(v,k) in (rowResumeMedis.aranresm_alergi||[])" :key="k+'ob'">
                                        <td>
                                            <div class="result_tab">
                                            {{v.jenis||"-"}}
                                            </div>
                                        </td>
                                        <td>
                                            <div class="result_tab">
                                            {{v.name||"-"}}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                      </div>
                      <div class="form-row" v-else>
                          <div class="col-md-12">
                              <span>Tidak Ada Alergi</span>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Tanda-Tanda Vital</h6>
                </div>
                <div class="card-body p-3">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Tekanan Darah</h4>
                              <p v-if="rowResumeMedis.aranresm_sistolik && rowResumeMedis.aranresm_diastolik">{{rowResumeMedis.aranresm_sistolik||"-"}}/
                              {{rowResumeMedis.aranresm_diastolik}} mmHG</p>
                              <p v-else> - </p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Nadi</h4>
                              <p v-if="rowResumeMedis.aranresm_nadi">{{rowResumeMedis.aranresm_nadi}}x/mnt <span v-if="rowResumeMedis.aranresm_nadi_type">- {{rowResumeMedis.aranresm_nadi_type||"-"}}</span>
                              </p>
                              <p v-else> - </p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Gula Darah</h4>
                              <p v-if="rowResumeMedis.aranresm_gda">{{rowResumeMedis.aranresm_gda}}mg/dL</p>
                              <p v-else> - </p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Pernafasan</h4>
                              <p v-if="rowResumeMedis.aranresm_pernafasan">{{rowResumeMedis.aranresm_pernafasan}}x/mnt</p>
                              <p v-else> - </p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Suhu</h4>
                              <p v-if="rowResumeMedis.aranresm_suhu">{{rowResumeMedis.aranresm_suhu}} &deg;C</p>
                              <p v-else> - </p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Kesadaran</h4>
                              <template v-if="tingkatKesadaranTotal">
                                  <div class="">
                                  <span class="font-weight-semibold">Nilai GCS: {{tingkatKesadaranTotal}}</span>
                                  <span v-if="tingkatKesadaranTotal >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span> 
                                  <span v-else-if="tingkatKesadaranTotal >= 12 && tingkatKesadaranTotal <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                                  <span v-else-if="tingkatKesadaranTotal >= 10 && tingkatKesadaranTotal <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                                  <span v-else-if="tingkatKesadaranTotal >= 7 && tingkatKesadaranTotal <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                                  <span v-else-if="tingkatKesadaranTotal >= 5 && tingkatKesadaranTotal <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                                  <span v-else-if="tingkatKesadaranTotal >= 4 && tingkatKesadaranTotal <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                                  <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
                                  </div>
                                  
                                  <div class="">
                                      <span class="font-weight-semibold mr-3">E : {{rowResumeMedis.aranresm_gcs_response_mata||"-"}}</span>
                                      <span class="font-weight-semibold mr-3">M : {{rowResumeMedis.aranresm_gcs_response_motorik||"-"}}</span>
                                      <span class="font-weight-semibold mr-3">V : {{rowResumeMedis.aranresm_gcs_response_verbal||"-"}}</span>
                                  </div>
                              </template>
                              <p v-else> - </p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              
              <div class="card mb-2">
                  <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Pemeriksaan Fisik</h6>
                  </div>
                  <div class="card-body p-3">
                      <div class="card border shadow-0">
                          <div class="bg-light card-header c-pointer"
                          @click="pemeriksaanFisik = !pemeriksaanFisik"
                          :class="pemeriksaanFisik ? null : 'collapsed'"
                          :aria-expanded="pemeriksaanFisik ? 'true' : 'false'"
                          aria-controls="GenPartRow">
                          <div class="d-flex align-items-center justify-content-between">
                              <span class="card-title font-weight-semibold">Klik untuk
                              {{ pemeriksaanFisik ? "Menyembunyikan" : "Menampilkan" }} Bagian Keadaan
                              Umum</span>
                              <i class="icon-arrow-down22" style="font-size: 20px;"></i>
                          </div>
                          </div>
                      <div>
                      
                      <b-collapse id="GenPartRow" v-model="pemeriksaanFisik">
                          <div class="row g-0">
                              <div class="col-md-6" v-for="(genCol, genColKey) in oddEvenGenCondition"
                              :key="genColKey+'genCol'">
                              <div class="gen-part" v-for="(vs,ks) in genCol" :key="ks+'sign'">
                                  <div
                                  class="d-flex align-items-center justify-content-between gen-part-head c-pointer"
                                  @click="rowVerifikasi[vs.value+'isOpen'] = !rowVerifikasi[vs.value+'isOpen']"
                                  :class="rowVerifikasi[vs.value+'isOpen'] ? null : 'collapsed'">
                                  <div class="font-weight-semibold">{{vs.label}}
                                  </div>
                                  <i class="icon-arrow-down22"></i>
                                  </div>

                                  <b-collapse :id="vs.value" v-model="rowVerifikasi[vs.value+'isOpen']"
                                  accordion="accKeadaanUmum">
                                  <div class="gen-part-body mt-1">
                                      <div class="font-weight-semibold text-info">
                                        {{getConfigDynamic(Config.mr.normalTidakNormalOptTP,rowVerifikasi[vs.value])||"-"}}
                                      </div>
                                      <div class="gen-input" v-if="rowVerifikasi[vs.value] !== 'TP'">
                                              <template v-if="vs.value == 'aranrv_vs_saraf' && rowVerifikasi[vs.value] == 'TN'">
                                                  <div class="gen-input-saraf" data-select2-id="162">
                                                  <div class="mb-2">
                                                      <label for="refleksFisiologis">Refleks Fisiologis</label>
                                                      <pre class="pre-input mb-1">
                                                      {{rowVerifikasi['aranrv_vs_saraf_r_fisiologis']}}
                                                      </pre>
                                                  </div>
                                                  <div class="mb-2">
                                                      <label for="refleksPatologis">Refleks Patologis</label>
                                                      <pre class="pre-input mb-1">
                                                      {{rowVerifikasi['aranrv_vs_saraf_r_patologis']}}
                                                      </pre>
                                                  </div>

                                                  <div class="row g-1">
                                                      
                                                      <div class="col-md-6">
                                                          <label>Babinski</label>
                                                          <pre class="pre-input mb-1">
                                                          {{rowVerifikasi['aranrv_vs_saraf_babinski']}}
                                                          </pre>
                                                      </div>

                                                      <div class="col-md-6">
                                                          <label>Lasegue</label>
                                                          <pre class="pre-input mb-1">
                                                          {{rowVerifikasi['aranrv_vs_saraf_lasegue']}}
                                                          </pre>
                                                      </div>
                                                      
                                                      <div class="col-md-6">
                                                          <label>Bregard</label>
                                                          <pre class="pre-input mb-1">
                                                          {{rowVerifikasi['aranrv_vs_saraf_bregard']}}
                                                          </pre>
                                                      </div>
                                                      
                                                      <div class="col-md-6">
                                                          <label>Sicard</label>
                                                          <pre class="pre-input mb-1">
                                                          {{rowVerifikasi['aranrv_vs_saraf_sicard']}}
                                                          </pre>
                                                      </div>
                                                      
                                                      <div class="col-md-6">
                                                          <label>Kaku Duduk</label>
                                                          <pre class="pre-input mb-1">
                                                          {{rowVerifikasi['aranrv_vs_saraf_kaku_duduk']}}
                                                          </pre>
                                                      </div>

                                                      <div class="col-md-6">
                                                          <label>Kernig Sign</label>
                                                          <pre class="pre-input mb-1">
                                                          {{rowVerifikasi['aranrv_vs_saraf_kernig_sign']}}
                                                          </pre>
                                                      </div>

                                                      <div class="col-md-6">
                                                          <label>Brudzinski 1</label>
                                                          <pre class="pre-input mb-1">
                                                          {{rowVerifikasi['aranrv_vs_saraf_brudzinski']}}
                                                          </pre>
                                                      </div>
                                                      
                                                      <div class="col-md-6">
                                                          <label>Brudzinski 2</label>
                                                          <pre class="pre-input mb-1">
                                                          {{rowVerifikasi['aranrv_vs_saraf_brudzinski2']}}
                                                          </pre>
                                                      </div>

                                                  </div>
                                                  
                                                  </div>
                                              </template>
                                              <template v-else>
                                                  <pre class="pre-input mb-1">
                                                  {{rowVerifikasi[vs.value+'_text']}}
                                                  </pre>
                                              </template>
                                      </div>
                                  </div>
                                  </b-collapse>
                              </div>
                              </div>
                          </div>
                      </b-collapse>
                      </div>
                  </div>
                  </div>
              </div>
              
              <div class="card-body">
                  <div class="alert alert-info">Verifikasi pilihan ICD-10 dan ICD-9-CM</div>
                  
                  <div class="card">
                  <div class="card-header bg_head_panel shadow-0 border">
                      <span class="card-title font-weight-semibold mb-0">Verifikasi ICD</span>
                  </div>

                  <div class="card-group">
                      <div class="card border shadow-0">
                        <div class="bg-light card-header">
                            <span class="card-title mb-0 font-weight-semibold">Diagnosis Dokter</span>
                        </div>
                        <div class="card-body py-2" v-if="(dataICD10Dok||[]).length">
                            <div class="req-list">
                            <h6 class="text-uppercase">ICD-10-CM dipilih:</h6>
                            <div v-for="(v,k) in (dataICD10Dok||[])" :key="k">
                                <template v-if="v.mi10_name">
                                <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                <span class="align-middle" data-popup="tooltip"
                                    >{{v.mi10_name}} <span
                                    class="font-weight-semibold">[{{v.mi10_code}}]</span></span>
                                </template>
                            </div>
                            </div>
                        </div>
                        <div class="card-body py-2" v-else>
                            <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-10-CM dipilih</label>
                        </div>

                        <div class="card-body">
                          <div class="req-list">
                            <h6 class="text-uppercase mb-0">Diagnosa Lainnya</h6>
                            <div v-if="(dataICD10DokLainnya||[]).length">
                              <div class="req-list">
                                <div v-for="(v,k) in (dataICD10DokLainnya||[])" :key="k">
                                    <template v-if="v.text">
                                      <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                      <span class="align-middle" data-popup="tooltip"
                                          >{{v.text}}</span>
                                    </template>
                                </div>
                                </div>
                            </div>
                            <div v-else>
                              <label for="" class="font-weight-semibold mb-0">Tidak ada diagnosa lainnya</label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="card border shadow-0">
                        <div class="bg-light card-header">
                            <span class="card-title mb-0 font-weight-semibold">Diagnosis Dokter Verifikator</span>
                        </div>
                        
                        <div class="card-body py-2" v-if="(dataICD10Verifikator||[]).length">
                            <div class="req-list">
                            <h6 class="text-uppercase">ICD-10-CM dipilih:</h6>
                            <div v-for="(v,k) in (dataICD10Verifikator||[])" :key="k">
                                <template v-if="v.mi10_name">
                                <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                <span class="align-middle" data-popup="tooltip"
                                    >{{v.mi10_name}} <span
                                    class="font-weight-semibold">[{{v.mi10_code}}]</span>
                                    <span v-if="v.aranicd10v_is_primary == 'Y'"
                                    class="font-weight-semibold text-info"> (Primer)</span>

                                    </span>
                                </template>
                            </div>
                            </div>
                        </div>
                        <div class="card-body py-2" v-else>
                            <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-10-CM dipilih</label>
                        </div>

                      </div>
                  </div>
                  <div class="card-group">                  
                      <div class="card border shadow-0">
                        <div class="bg-light card-header">
                          <span class="card-title mb-0 font-weight-semibold">Diagnosis Rekam Medis</span>
                        </div>
                        <div class="card-body py-2" v-if="(dataICD10RM||[]).length">
                            <div class="req-list">
                            <h6 class="text-uppercase">ICD-10-CM dipilih:</h6>
                            <div v-for="(v,k) in (dataICD10RM||[])" :key="k">
                                <template v-if="v.mi10_name">
                                <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                <span class="align-middle" data-popup="tooltip"
                                    >{{v.mi10_name}} <span
                                    class="font-weight-semibold">[{{v.mi10_code}}]</span>
                                    <span v-if="v.aranicd10r_is_primary == 'Y'"
                                    class="font-weight-semibold text-info"> (Primer)</span>    
                                </span>
                                </template>
                            </div>
                            </div>
                        </div>
                        <div class="card-body py-2" v-else>
                            <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-10-CM dipilih</label>
                        </div>
                      </div>
                      
                      <div class="card border shadow-0">
                        <div class="bg-light card-header">
                          <span class="card-title mb-0 font-weight-semibold">Diagnosis Upla</span>
                        </div>
                        
                        <div class="card-body py-2" v-if="(dataICD10UPLA||[]).length">
                            <div class="req-list">
                            <h6 class="text-uppercase">ICD-10-CM dipilih:</h6>
                            <div v-for="(v,k) in (dataICD10UPLA||[])" :key="k">
                                <template v-if="v.mi10u_name">
                                <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                <span class="align-middle" data-popup="tooltip"
                                    >{{v.mi10u_name}} <span
                                    class="font-weight-semibold">[{{v.mi10u_code}}]</span>
                                    <span v-if="v.aranicd10u_is_primary == 'Y'"
                                    class="font-weight-semibold text-info"> (Primer)</span>  
                                </span>
                                </template>
                            </div>
                            </div>
                        </div>
                        <div class="card-body py-2" v-else>
                            <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-10-CM dipilih</label>
                        </div>

                      </div>
                  </div>

                  <div class="card-group mt-5">
                      <div class="card border shadow-0">
                        <div class="bg-light card-header">
                            <span class="card-title mb-0 font-weight-semibold">Tindakan Dokter</span>
                        </div>
                        <div class="card-body py-2" v-if="(dataICD9Dok||[]).length">
                            <div class="req-list">
                            <h6 class="text-uppercase">ICD-9-CM dipilih:</h6>
                            <div v-for="(v,k) in (dataICD9Dok||[])" :key="k">
                                <template v-if="v.mi9_name">
                                <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                <span class="align-middle" data-popup="tooltip"
                                    >{{v.mi9_name}} <span
                                    class="font-weight-semibold">[{{v.mi9_code}}]</span></span>
                                
                                  <a href="javascript:;" @click="openInformConsentDetail(v)" v-if="v.aranci9d_informed_consent" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal"  v-b-tooltip.hover title="Lihat Informed Consent">
                                  <i class="icon-file-eye2"></i>
                                  </a>
                                </template>
                            </div>
                            </div>
                        </div>
                        <div class="card-body py-2" v-else>
                            <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-9-CM dipilih</label>
                        </div>

                        <div class="card-body">
                          <div class="req-list">
                            <h6 class="text-uppercase mb-0">Tindakan Lainnya</h6>
                            <div v-if="(dataICD9DokLainnya||[]).length">
                              <div class="req-list">
                                <div v-for="(v,k) in (dataICD9DokLainnya||[])" :key="k">
                                    <template v-if="v.text">
                                      <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                      <span class="align-middle" data-popup="tooltip"
                                          >{{v.text}}</span>
                                    </template>
                                </div>
                                </div>
                            </div>
                            <div v-else>
                              <label for="" class="font-weight-semibold mb-0">Tidak ada diagnosa lainnya</label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="card border shadow-0">
                        <div class="bg-light card-header">
                            <span class="card-title mb-0 font-weight-semibold">Tindakan Dokter Verifikator</span>
                        </div>
                        <div class="card-body py-2" v-if="(dataICD9Verifikator||[]).length">
                            <div class="req-list">
                            <h6 class="text-uppercase">ICD-9-CM dipilih:</h6>
                            <div v-for="(v,k) in (dataICD9Verifikator||[])" :key="k">
                                <template v-if="v.mi9_name">
                                <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                <span class="align-middle" data-popup="tooltip"
                                    >{{v.mi9_name}} <span
                                    class="font-weight-semibold">[{{v.mi9_code}}]</span></span>
                                </template>
                            </div>
                            </div>
                        </div>
                        <div class="card-body py-2" v-else>
                            <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-9-CM dipilih</label>
                        </div>
                      </div>
                  </div>
                  <div class="card-group">                  
                      <div class="card border shadow-0">
                        <div class="bg-light card-header">
                            <span class="card-title mb-0 font-weight-semibold">Tindakan Rekam Medis</span>
                        </div>
                        <div class="card-body py-2" v-if="(dataICD9RM||[]).length">
                            <div class="req-list">
                            <h6 class="text-uppercase">ICD-9-CM dipilih:</h6>
                            <div v-for="(v,k) in (dataICD9RM||[])" :key="k">
                                <template v-if="v.mi9_name">
                                <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                <span class="align-middle" data-popup="tooltip"
                                    >{{v.mi9_name}} <span
                                    class="font-weight-semibold">[{{v.mi9_code}}]</span></span>
                                </template>
                            </div>
                            </div>
                        </div>
                        <div class="card-body py-2" v-else>
                            <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-9-CM dipilih</label>
                        </div>
                      </div>
                          
                      <div class="card border shadow-0">
                        <div class="bg-light card-header">
                            <span class="card-title mb-0 font-weight-semibold">Tindakan UPLA</span>
                        </div>
                        <div class="card-body py-2" v-if="(dataICD9UPLA||[]).length">
                            <div class="req-list">
                            <h6 class="text-uppercase">ICD-9-CM dipilih:</h6>
                            <div v-for="(v,k) in (dataICD9UPLA||[])" :key="k">
                                <template v-if="v.mi9u_name">
                                <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                <span class="align-middle" data-popup="tooltip"
                                    >{{v.mi9u_name}} <span
                                    class="font-weight-semibold">[{{v.mi9u_code}}]</span></span>
                                </template>
                            </div>
                            </div>
                        </div>
                        <div class="card-body py-2" v-else>
                            <label for="" class="font-weight-semibold mb-0">Belum ada item ICD-9-CM dipilih</label>
                        </div>
                      </div>
                  </div>

                  </div>
              </div>

              <table class="table table-sm table-bordered">
                <tr>
                  <td class="align-top" width="50%">
                    <div class="result_tab">
                      <h4>Pemeriksaan Penunjang</h4>
                      <table class="table table-sm table-bordered">
                            <thead>
                              <tr>
                                <td></td>
                                <th>Hasil Pemeriksaan</th>
                                <th>Hasil</th>
                              </tr>
                            </thead>
                            <tbody>
                              <template v-for="(v,k) in tindakan.lab">
                                <tr v-for="(dtHasil, keyHasil) in v.arantl_hasil" :key="`lkh-${k}-${keyHasil}`" :id="k">
                                  <td :rowspan="v.arantl_hasil.length == 1 ? 1 : v.arantl_hasil.length" v-if="keyHasil == 0">
                                    <label>
                                      Laboratorium

                                      <ul class="pl-3">
                                        <li v-for="(dtTindakan, keyTindakan) in v.arantl_tindakan.split(', ')" :key="`atin-${keyTindakan}`">
                                          {{ dtTindakan }}
                                        </li>
                                      </ul>
                                    </label>
                                  </td>
                                  <td>
                                    {{ dtHasil.value }}
                                  </td>
                                  <td>
                                    <a :href="uploader(dtHasil.file)" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal" v-if="dtHasil.file">
                                      <i class="icon-file-eye2"></i>
                                    </a>
                                  </td>
                                </tr>
                              </template>
                              
                              <tr v-if="!(tindakan.lab||[]).length">
                                <td>
                                  <label>
                                    Laboratorium
                                  </label>
                                </td>
                                <td>
                                  <span>Tidak ada pemeriksaan</span>
                                </td>
                                <td>
                                </td>
                              </tr>

                              <template v-for="(v,k) in tindakan.radiologi">
                                <tr v-for="(dtHasil, keyHasil) in v.arantr_hasil" :key="`rkh-${k}-${keyHasil}`" :id="k">
                                  <td :rowspan="v.arantr_hasil.length == 1 ? 1 : v.arantr_hasil.length" v-if="keyHasil == 0">
                                    <label>
                                      Radiologi

                                      <ul class="pl-3">
                                        <li v-for="(dtTindakan, keyTindakan) in v.arantr_tindakan.split(', ')" :key="`ratin-${keyTindakan}`">
                                          {{ dtTindakan }}
                                        </li>
                                      </ul>
                                    </label>
                                  </td>
                                  <td>
                                    {{ dtHasil.value }}
                                  </td>
                                  <td>
                                    <a :href="uploader(dtHasil.file)" class="btn btn-sm btn-icon alpha-teal text-teal-800 border-teal" v-if="dtHasil.file">
                                      <i class="icon-file-eye2"></i>
                                    </a>
                                  </td>
                                </tr>
                              </template>
                              <tr v-if="!(tindakan.radiologi||[]).length">
                                <td>
                                  <label>
                                    Radiologi
                                  </label>
                                </td>
                                <td>
                                  <span>Tidak ada pemeriksaan</span>
                                </td>
                                <td>
                                </td>
                              </tr>
                              
                              <tr v-for="(v, k) in tindakan.fisio" :key="`tf-${k}`">
                                <td>
                                  <label>
                                    Fisioterapi
                                  </label>

                                  <ul class="pl-3">
                                    <li v-for="(dtTindakan, keyTindakan) in [...(v.arantf_elektroterapi||[]), ...(v.arantf_mekanoterapi||[]), ...(v.arantf_latihan||[])]" :key="`fatin-${keyTindakan}`">
                                      <span v-if="dtTindakan == 'LE'">
                                      {{v.arantf_elektroterapi_text||"-"}}</span>
                                      <span v-else-if="dtTindakan == 'LM'">
                                      {{v.arantf_mekanoterapi_text||"-"}}</span>
                                      <span v-else-if="dtTindakan == 'LL'">
                                      {{v.arantf_latihan_text||"-"}}</span>
                                      <span v-else>{{ dtTindakan }}</span>
                                    </li>
                                  </ul>
                                </td>
                                <td>
                                  <span>Tidak ada pemeriksaan</span>
                                </td>
                                <td></td>
                              </tr>
                              <tr v-if="!(tindakan.fisio||[]).length">
                                <td>
                                  <label>
                                    Fisioterapi
                                  </label>
                                </td>
                                <td>
                                  <span>Tidak ada pemeriksaan</span>
                                </td>
                                <td>
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <label>
                                    Lain-lain
                                  </label>
                                </td>
                                <td>-</td>
                                <td>-</td>
                              </tr>
                            </tbody>
                          </table>

                    </div>
                  </td>
                  <td class="align-top" width="50%">
                    <div class="result_tab">
                      <h4>Konsultasi/Pengobatan</h4>
                      <p>{{rowResumeMedis.aranresm_consultation||"-"}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="align-top">
                    <div class="result_tab">
                      <h4>Keadaan Keluar</h4>
                      <p>{{rowResumeMedis.aranresm_keadaan_keluar||"-"}}</p>
                    </div>
                  </td>
                  <td class="align-top">
                    <div class="result_tab">
                      <h4>Cara Keluar</h4>
                      <p>{{rowResumeMedis.aranresm_cara_keluar||"-"}}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="align-top">
                    <div class="result_tab">
                      <h4>Tindak Lanjut</h4>
                      <p>{{rowResumeMedis.aranresm_tindak_lanjut||"-"}}</p>
                    </div>
                  </td>
                  <td class="align-top">
                    <div class="result_tab">
                      <h4>Anjuran/Edukasi</h4>
                      <p>{{rowResumeMedis.aranresm_reccomendation||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div class="card-footer">
              <div class="text-right">
                <button @click="$router.back()" type="button" class="btn btn-transparent ml-auto mr-2">Back</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
              </div>
            </div>
            <!-- /dashboard content -->
          </div>
        </b-form>
      </validation-observer>
    </div>

    
    <b-modal v-model="openModalICDetail" :title="'Detail Informed Consent'" size="lg" ok-only>
        <table class="table table-bordered table-sm table-input">
        <thead>
            <tr>
            <td colspan="3">
                <div class="result_tab">
                    <h4>Nama Tindakan / ICD-9-CM</h4>
                    <p>{{rowIC.mi9_name}} <span class="font-weight-semibold">[{{rowIC.mi9_code}}]</span></p>
                </div>
            </td>
            </tr>
            <tr>
            <th width="24">No.</th>
            <th>Jenis Informasi</th>
            <th>Isi Informasi</th>
            </tr>
        </thead>
        <tbody>
            <tr>
            <td>1</td>
            <td>Diagnosis<br />(Diagnosis Kerja & Diagnosis Banding)</td>
            <td>
            {{rowIC.auic_diagnosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>2</td>
            <td>Dasar Diagnosis</td>
            <td>
            {{rowIC.auic_dasar_diagnosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>3</td>
            <td>Tindakan Kedokteran</td>
            <td>
            {{rowIC.auic_tindakan_dokter||"-"}}
            </td>
            </tr>
            <tr>
            <td>4</td>
            <td>Indikasi Tindakan</td>
            <td>
            {{rowIC.auic_indikasi_tindakan||"-"}}
            </td>
            </tr>
            <tr>
            <td>5</td>
            <td>Tata Cara</td>
            <td>
            {{rowIC.auic_tata_cara||"-"}}
            </td>
            </tr>
            <tr>
            <td>6</td>
            <td>Tujuan</td>
            <td>
            {{rowIC.auic_tujuan||"-"}}
            </td>
            </tr>
            <tr>
            <td>7</td>
            <td>Risiko</td>
            <td>
            {{rowIC.auic_risiko||"-"}}
            </td>
            </tr>
            <tr>
            <td>8</td>
            <td>Komplikasi</td>
            <td>
            {{rowIC.auic_kompilasi||"-"}}
            </td>
            </tr>
            <tr>
            <td>9</td>
            <td>Prognosis</td>
            <td>
            {{rowIC.auic_prognosis||"-"}}
            </td>
            </tr>
            <tr>
            <td>10</td>
            <td>Alternatif & Risiko</td>
            <td>
            {{rowIC.auic_alternatif||"-"}}
            </td>
            </tr>
            <tr>
            <td>11</td>
            <td>Lain-lain</td>
            <td>
            {{rowIC.auic_lainnya||"-"}}
            </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
            <td width="50%" colspan="2">
                <div class="result_tab">
                <h4>Apakah pasien/pendamping pasien menyetujui tindakan?</h4>
                <p v-if="rowIC.auic_is_pasien_setuju == 'Y'">Tindakan disetujui</p>
                <p v-else>Tindakan ditolak</p>
                </div>
            </td>
            <td width="50%">
                <div class="result_tab">
                <h4>Persetujuan tindakan diberikan kepada</h4>
                <p v-if="rowIC.auic_persetujuan_tindakan == 'P'">Pasien</p>
                <p v-else>Penanggung Jawab</p>
                </div>
            </td>
            </tr>
        </tfoot>
        </table>
    </b-modal>
  </div>
</template>

<script>
import PatientInfo from '@/components/Ranap/PatientInfo.vue'
import Gen from '@/libs/Gen'
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._

export default {
  extends: GlobalVue,
  components: {
    PatientInfo
  },
  data() {
    return {
      loading: {
        patientInfo: false
      },
      patientData: {},
      rowReg: {},
      rowResumeMedis: {},
      tindakan: [],

      dataICD10Primer: null,
      dataICD9Verifikator: [],
      dataICD9Dok: [],
      dataICD9DokLainnya: [],
      dataICD9RM: [],
      dataICD9UPLA: [],      

      dataICD10Verifikator: [],
      dataICD10Dok: [],
      dataICD10DokLainnya: [],
      dataICD10RM: [],
      dataICD10UPLA: [],


      mICD9: [],
      mICD10: [],
      
      inputICD9: null,
      inputICD10: null,
      inputICD9Name: null,
      inputICD10Name: null,
      searchICD10Name: null,
      searchICD9Name: null,

      openModalICDetail: false,
      rowIC: {},
      rowVerifikasi: {},

      pemeriksaanFisik : false,

    }
  },
  computed: {
    tingkatKesadaranTotal(){
      let total = ((this.rowResumeMedis.aranresm_gcs_response_mata||0) + (this.rowResumeMedis.aranresm_gcs_response_verbal||0) + (this.rowResumeMedis.aranresm_gcs_response_motorik||0))
      return total
    },
    oddEvenGenCondition() {
        let odd = this.Config.mr.configVitalSignRanapVerifikator.filter((el, index) => index % 2)
        let even = this.Config.mr.configVitalSignRanapVerifikator.filter((el, index) => !(index % 2))
        let res = []
        res.push(even)
        res.push(odd)
        return res
    }
  },
  methods: {
    getConfigDynamic(master,value){
      let text = ''
      if(value){
          let index = (master||[]).findIndex(x => x.value == value)
          if(index !== -1){
              text = master[index]['text']
          }
      }
      return text
    },
    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.params.pageSlug
          }
        },
        "POST"
      ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)
        this.$set(this.loading, 'patientInfo', false)
      })
    },
    
    apiGetMaster() {
      let slugs = (this.pageSlug ? "/" + this.pageSlug : "") + (this.pageId ? "/" + this.pageId : "")

      Gen.apiRest(
        "/get/" + this.modulePage +
        slugs + `?master=1`, {}
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })
      })
    },

    openInformConsentDetail(v){
        Gen.apiRest('/do/' + 'RanapCPPTDokter', {
            data: {
              type: 'get-data-ic',
              id: v.aranci9d_informed_consent,
            }
        }, 'POST').then(res => {
            this.rowIC = res.data.row 
            this.rowIC.mi9_name = v.mi9_name
            this.rowIC.mi9_code = v.mi9_code
            this.rowIC.id_icd = v.aranci9d_id
            this.openModalICDetail = true                   
        })
    },
    
    doSubmit() {
      this.$refs['VFormSubmit'].validate().then(success => {
        if (!success) {
          return this.$swal({
            icon: 'error',
            title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
            if (result.value) {
              setTimeout(() => {
                let inv = []
                let el = document.querySelectorAll('.label_error')
                for (let i = 0; i < (el || []).length; i++) {
                  if (el[i].style.display !== 'none') {
                    inv.push(el[i].id)
                  }
                }
                if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                  behavior: 'smooth',
                  block: 'center'
                })
              }, 500)
            }
          })
        }
        
        if (success) {
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.rowVerifikasi
              data.dataICD9 = this.dataICD9
              data.aranr_status = this.rowReg.aranr_status

              for (let i = 0; i < (this.dataICD10 || []).length; i++) {
                this.dataICD10[i]['aranicd10v_is_primary'] = 'N'
              }
              data.dataICD10 = this.dataICD10
              if ((data.dataICD10 || []).length) {
                let indexPrimary2 = this.dataICD10.findIndex(x => x.aranicd10v_id == this.dataICD10Primer)
                data.dataICD10[indexPrimary2]['aranicd10v_is_primary'] = 'Y'
              }

              data.aranr_id = this.rowReg.aranr_id
              data.type = 'submit-rm'
              
              Gen.apiRest(
                "/do/" + 'RanapVerifPiutang', {
                  data: data
                },
                "POST"
              ).then(res => {
                this.loadingOverlay = false
                let resp = res.data
                resp.statusType = 200
                this.$swal({
                  title: resp.message,
                  icon: resp.status,
                  confirmButtonText: 'Ok'
                }).then(result => {
                  if (result.value) {
                    this.$router.push({
                      name: 'RanapVerifPiutang'
                    }).catch(() => {})
                  }
                })
              }).catch(err => {
                this.loadingOverlay = false
                if (err) {
                  err.statusType = err.status
                  err.status = "error"
                  err.title = err.response?.data?.title
                  err.message = err.response?.data?.message
                  err.messageError = err.message
                }
                this.doSetAlertForm(err)
              })
            }
          })
        }
      })
    },

  },
  mounted() {
    this.getPatientInfo()
    this.apiGet()
    this.apiGetMaster()
  },
  watch: {
    $route() {
      this.apiGet()
      this.apiGetMaster()
    }
  }
}

</script>